import React from "react"
import Layout from "../../components/Layout"
import { Helmet } from "react-helmet"
import Nav from "../../components/Nav"

export default function CaseStudy() {
    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Kevan Lin | Case Studies | Amazon | Comparison shopping</title>
                <link rel="canonical" href="http://www.kevanlin.com/" />
            </Helmet>
            <Nav></Nav>
            <div className="case-study">
                <main>
                    <div className="wrapper">
                        <div className="case-study--hero">
                            <span className="lozenge">Case study</span>
                            <h1>Big Headline goes here</h1>
                            <h2>Informative subheading goes here</h2>
                        </div>
                        <div className="flex-h">
                            <section className="case-study--background flex-60">
                                <h3>Background</h3>
                                <p>Background text goes here</p>
                                <blockquote>How might we?</blockquote>
                            </section>
                            <section className="case-study--details flex-40">
                                <div className="flex-h">
                                    <dl>
                                        <dt>Role</dt>
                                        <dd>Role?</dd>
                                    </dl>
                                    <dl>
                                        <dt>Contribution</dt>
                                        <dd>Activity #1</dd>
                                        <dd>Activity #2</dd>
                                    </dl>
                                    <dl>
                                        <dt>Collaborators</dt>
                                        <dd>Collab partner #1</dd>
                                        <dd>Collab partner #1</dd>
                                        <dd>Collab partner #1</dd>
                                    </dl>
                                </div>
                            </section>
                        </div>
                        <section className="case-study--problem">
                                <h3>Problem statement</h3>
                                <p>Problem statement goes here.</p>
                        </section>
                        <section className="case-study--business-metrics">
                            <h3>Business metrics</h3>
                            <ol>
                                <li>OKR</li>
                            </ol>
                        </section>
                        <section className="case-study--design-process">
                            <div>
                                <h3>Design process</h3>
                                <div className="double-diamond">
                                    <div className="double-diamond-section">
                                        <h3>Discover</h3>
                                        <p>HMW</p>
                                        <ul>
                                            <li>Activity</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Define</h3>
                                        <p>Question</p>
                                        <ul>
                                            <li>Activity</li>
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Develop</h3>
                                        <p>HMW?</p>
                                        <ul>
                                            <li>Activity</li>                                         
                                        </ul>
                                    </div>
                                    <div className="double-diamond-section">
                                        <h3>Deliver</h3>
                                        <p>Outcome</p>
                                        <ul>
                                            <li>Activity</li>
                                        </ul>
                                    </div>
                                </div>
                                <section id="discovery">
                                    <h4>Discovery phase</h4>
                                    <p><em>Discover insights into the problem by uncovering the problem space</em></p>
                                    <p>Describe the discovery phase</p>
                                </section>
                                <section id="definition">
                                    <h4>Definition</h4>
                                    <p><em>Defining the area to focus efforts</em></p>
                                </section>
                                <section id="develop">
                                    <h4>Develop</h4>
                                    <p><em>Develop potential solutions aka prototyping</em></p>
                                    <p>Describe the develop phase</p>
                                </section>
                                <section id="deliver">
                                    <h4>Deliver</h4>
                                    <p><em>Deliver an experience with confidence</em></p>
                                    <p>Describe the delivery phase</p>
                                </section>
                            </div>
                        </section>
                    </div>
                </main>
            </div>
        </Layout>
        
    )
}