import React from "react"
import { Link } from "gatsby"

export default function NavBar() {

    const pathname = typeof window !== 'undefined' ? window.location.pathname : '';
    console.log(pathname);
    
    return (
        <nav>
            <div className="nav--container">
                <div className="nav--left">
                    <Link to="/" title="Home"><span>Portfolio of Kevan Lin</span></Link>
                </div>
                <div className="nav--right">
                    <Link to="/my-design-process" title="Design Process"><span>Process</span></Link>
                    <Link to="/portfolio" title="My portfolio"><span>Portfolio</span></Link>
                    <Link to="/contact" title="Contact me"><span>Contact</span></Link>
                </div>
            </div>
        </nav>
    )
}